import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');

  const handleLogin = async () => {
    const res = await fetch('/app/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
    });

    const data = await res.json();
    if (!res.ok) {
      setMsg(data.error || 'Login failed');
    } else {
      localStorage.setItem('token', data.token);
      onLogin(data.displayName);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 bg-light">
      <div className="card shadow p-4" style={{ width: '100%', maxWidth: '400px' }}>
        <h3 className="mb-4 text-center">🔐 Login to Access RAG</h3>
        <div className="mb-3">
          <input
            className="form-control"
            type="email"
            placeholder="Email address"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <input
            className="form-control"
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <button className="btn btn-primary w-100 mb-2" onClick={handleLogin}>
          Submit
        </button>
        {msg && <div className="text-danger text-center">{msg}</div>}
      </div>
    </div>
  );
}
